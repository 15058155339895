.Header {
  width: 100%;
  background: #cbcbcb;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  /* border-bottom: 5px white solid; */
  position: relative;
  padding-bottom: 10px;
  /* animation: gradientAnimation  5s ease-in-out infinite ;  */
}
.Header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px; /* Border thickness */
  background-color: white; /* Border color */
  animation: borderAnimation 2s linear infinite; /* Adjust animation duration as needed */
}

@keyframes borderAnimation {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes gradientAnimation {
  0% {
    background: linear-gradient(to right, gray, black);
  }
  100% {
    background: linear-gradient(to left, gray, black);
  }
}
.Header .RightHeader {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .LeftHeader {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .LeftHeader img {
  width: calc(110px + 5vw);
  margin: 2%;
}
