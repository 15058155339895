.Footer {
	width: 100%;
	background-color: red;
	margin-top: 100px;
	background: #cbcbcb;
	color: black;
	padding: 10px;
	font-size: calc(6px + 1vw);
	/* animation: gradientAnimation  5s ease-in-out infinite ;  */
	position: relative;
	padding-bottom: 10px;
	/* animation: gradientAnimation  5s ease-in-out infinite ;  */
}
.Footer::after {
	content: "";
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 3px; /* Border thickness */
	background-color: white; /* Border color */
	animation: borderAnimation 2s linear infinite; /* Adjust animation duration as needed */
}

@keyframes borderAnimation {
	0% {
		width: 0;
		left: 0;
	}
	50% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}

@keyframes gradientAnimation {
	0% {
		background: linear-gradient(to right, gray, black);
	}
	100% {
		background: linear-gradient(to left, gray, black);
	}
}
.Footer .FootersList {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.Footer .FootersList li {
	list-style: none;
}
.Footer p {
	text-align: center;
	margin: 0;
	padding: 0;
}
