.ProductInfo {
	display: flex;
	flex-direction: column;
	width: 75%;
	justify-content: space-between;
	margin: auto;
	row-gap: 10px;
}
.ProductInfo .img-thumbnail {
	background-color: gray;
}
