:root {
	--color1: #fff;
	--color2: #000000;
}

.Home {
	width: 100%;
	display: flex;
	background: var(--color1);
	justify-content: space-between;
	margin: auto;
	min-height: 90vh;
	flex-wrap: wrap-reverse;
}
.sideBar {
	min-width: 17%;
}

div.sideBar > div {
	position: fixed;
	width: 17%;
}
.Home .categories {
	min-width: 17%;
	margin: 20px;
	place-items: center;
}
.Home .Content {
	max-width: 65%;
	display: flex;
	justify-content: center;
	align-items: start;
}

.DashboardTable table > thead > tr > th {
	background-color: rgb(129, 129, 129);
	color: color1;
	text-align: center;
}
.DashboardTable table > li {
	background-color: red;
}

.DashboardTable ul li a {
	color: black;
}
@media screen and (max-width: 780px) {
	.Home {
		justify-content: center !important;
		flex-wrap: wrap !important;
		flex-direction: column-reverse;
	}
	.Home .Content {
		max-width: 100%;
	}
	.Home .categories {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		row-gap: 10px;
	}
	.Home .categories > div {
		max-width: 150px;
		width: 150px;
		height: 175px;
		max-height: 175px;
		overflow-y: auto;
	}
	.Home .categories > div > table thead {
		position: sticky;
		top: 0;
	}

	#root > div > div.Home.pt-5 > div.categories > div > table tr > th,
	.Home.pt-5 > div.categories td {
		font-size: 12px;
	}
}

/* Alert */

.alertPopup {
	background: var(--color1);
}

.alertPopupBrown {
	background: var(--color2);
}
.alertTitle {
	display: block;
	color: var(--color2);
}

.alertButton {
	background: var(--color2) !important ;
	color: var(--color1, var(--color1)) !important;
}
.alertcancel {
	background: red !important ;

	color: var(--color1, var(--color1)) !important;
}
.alertButtonVaild {
	border-radius: 40px !important;
	background: var(--Grey, #8a9092) !important;
	color: var(--color1, var(--color1)) !important;
}

.alertActions {
	padding-bottom: 50px;
}

body
	> div.swal2-container.swal2-center.swal2-backdrop-show
	> div
	> div.swal2-actions
	> button.swal2-confirm.swal2-styled:focus {
	box-shadow: none;
}
