.Home .products .Cart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.Home .products .Cart input {
  width: 50%;
  border: 1px solid gray;
  border-radius: 25px;
  padding: 0px 10px;
}
.Home .products .Cart button {
  font-size: 10px;
}
#root
  > div
  > div.Home.pt-5
  > div.Content.px-5.mt-3.container
  > main
  > div
  > div
  > div
  > ul {
  display: none !important;
}

#root
  > div
  > div.Home.pt-5
  > div.Content.px-5.mt-3.container
  > main
  > div
  > div
  > div
  > button.slick-arrow.slick-prev {
  background-color: gray !important;
  border-radius: 50%;
}
#root
  > div
  > div.Home.pt-5
  > div.Content.px-5.mt-3.container
  > main
  > div
  > div
  > div
  > button.slick-arrow.slick-next {
  background-color: gray !important;
  border-radius: 50%;
}
