.ShoppingCart {
  width: 100%;
}
.ShoppingCart .emptyCart {
  width: 100%;
  text-align: center;
  color: white;
  text-transform: capitalize;
  border: 3px solid white;
  border-radius: 15px;
}
div > table > thead > tr > th {
  border: none;
}
div > table > tbody > tr > td {
  border: none;
}
.ShoppingCart .CartButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.ShoppingCart .CartButtons button {
  width: 100px;
  text-transform: capitalize;
}
