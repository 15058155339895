.DashboardActions {
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.DashboardActions svg {
  color: gray;
  cursor: pointer;
}
